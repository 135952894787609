import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieBanner from '../components/CookieBanner';
import { useRouter } from 'next/dist/client/router';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <>
      <CookieBanner />
      <Header />
      <main className={router.pathname === '/' ? '' : 'px-8'}>
        <Component {...pageProps} />
      </main>
      <Footer />
    </>
  );
}
export default MyApp;
