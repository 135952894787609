import React from 'react';
import Link from 'next/link';

const AboutLinks = [
  { href: '/about-us', label: 'About us' },
  { href: '/members', label: 'Our members' },
  { href: '/latest-updates', label: 'Latest updates' },
  { href: '/events', label: 'Events' },
  { href: '/contact', label: 'Contact' }
];

const CharityLinks = [
  // { href: '/', label: 'Donate' },
  { href: '/charities', label: 'Work with us' }
];

const MembersLinks = [
  {
    href: 'https://members.roundtable.co.uk/member-login/?redirect_to=https%3A%2F%2Fmembers.roundtable.co.uk%2F',
    label: 'Login'
  },
  { href: 'https://members.roundtable.co.uk/benefits/', label: 'Benefits' },
  { href: 'https://members.roundtable.co.uk/news/', label: 'National RoundTable News' }
];

const Footer: React.FC = () => {
  return (
    <footer className="mx-auto w-full max-w-screen-xl px-5 mb-4 mt-auto">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:grid-cols-4 mb-8 text-sm mt-20">
        <div>
          <h4 className="mb-4 text-rt-orange text-lg">About</h4>
          <ul>
            {AboutLinks.map((link, index) => (
              <li key={index} className="mb-2">
                <Link href={link.href}>
                  <a className="text-white hover:underline">{link.label}</a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="mb-4 text-rt-orange text-lg">Charity</h4>
          <ul>
            {CharityLinks.map((link, index) => (
              <li key={index} className="mb-2">
                <Link href={link.href}>
                  <a className="text-white hover:underline">{link.label}</a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="mb-4 text-rt-orange text-lg">Members</h4>
          <ul>
            {MembersLinks.map((link, index) => (
              <li key={index} className="mb-2">
                <a
                  className="inline-flex items-center text-white hover:underline"
                  href={link.href}
                  target="_blank"
                  rel="noreferrer">
                  {link.label}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1 h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="mb-4 text-rt-orange text-lg">Social media</h4>
          <ul>
            <li className="mb-2">
              <a
                href="https://www.facebook.com/yorkroundtable"
                className="text-white hover:underline">
                Facebook
              </a>
            </li>
            <li className="mb-2">
              <a className="text-white hover:underline" href="https://twitter.com/yorkroundtable">
                Twitter
              </a>
            </li>
            <li className="mb-2">
              <a
                className="text-white hover:underline"
                href="https://www.instagram.com/yorkroundtable">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center border-t border-white border-opacity-10 py-4 text-white text-opacity-75">
        <p className="mb-2 md:mb-0 text-xs">
          {new Date().getFullYear()} &copy; York Round Table – Charity number: 1062097 –{' '}
          <Link href="/privacy-policy">
            <a className="underline hover:no-underline">Privacy Policy</a>
          </Link>
        </p>
        <p className="text-xs md:ml-auto">
          <a
            href="https://roundtable.co.uk/"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center hover:underline">
            National Round Table Website
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
