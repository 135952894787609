import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import logo from '../../public/roundtable.svg';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useRouter } from 'next/dist/client/router';

const Header: React.FC = () => {
  const router = useRouter();

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [isNavVisible, setIsNavVisible] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsNavVisible(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (
    <div className={`bg-white bg-opacity-5 ${router.pathname === '/' ? '' : 'mb-14'}`}>
      <header className="relative lg:h-header-bg-lg py-4 px-5 lg:px-6 mx-auto w-full max-w-screen-xl flex flex-wrap lg:flex-nowrap items-center">
        <Link href="/">
          <a
            className="lg:absolute lg:left-1/2 transition duration-1000 ease-in-out transform lg:-translate-x-1/2 hover:rotate-360 w-logo-sm lg:w-logo-lg"
            title="Homepage">
            <Image src={logo} alt="" />
            <span className="sr-only">Home</span>
          </a>
        </Link>
        <nav
          className={`lg:flex w-full order-3 mt-4 lg:mt-0 ${
            !isDesktop && !isNavVisible ? 'hidden' : ''
          }`}>
          <ul className="lg:flex lg:items-center">
            <li className="lg:mr-4 text-sm">
              <Link href="/">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Home
                </a>
              </Link>
            </li>
            <li className="lg:mx-4 text-sm">
              <Link href="/about-us">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  About us
                </a>
              </Link>
            </li>
            <li className="lg:mx-4 text-sm">
              <Link href="/members">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Our members
                </a>
              </Link>
            </li>
            <li className="lg:mx-4 text-sm">
              <Link href="/latest-updates">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Latest updates
                </a>
              </Link>
            </li>
            <li className="lg:mx-4 text-sm">
              <Link href="/events">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Events
                </a>
              </Link>
            </li>
          </ul>
          <ul className="lg:flex lg:items-center lg:ml-auto">
            <li className="lg:mr-4 text-sm">
              <Link href="/charities">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Charities &amp; Good Causes
                </a>
              </Link>
            </li>
            <li className="lg:mx-4 text-sm">
              <Link href="/contact">
                <a className="inline-block w-full p-4 bg-rt-dark-grey-tint lg:bg-transparent lg:w-auto lg:p-0 text-white hover:underline">
                  Contact us
                </a>
              </Link>
            </li>
            <li className="mt-4 lg:mt-0 lg:ml-4 text-sm">
              <Link href="/get-involved">
                <a className="inline-flex items-center w-full justify-center px-6 py-2 border border-rt-orange text-white rounded-full">
                  Get involved
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        <button
          className="inline-flex lg:hidden items-center ml-auto text-sm text-white"
          onClick={() => setIsNavVisible(!isNavVisible)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
          {isNavVisible ? 'Close' : 'Open'} menu
        </button>
      </header>
    </div>
  );
};

export default Header;
