import React from 'react';
import classNames from 'classnames';
import useCookie from 'react-use-cookie';
import Link from 'next/link';

const CookieBanner: React.FC = () => {
  const [cookieConsent, setCookieConsent] = useCookie('cookieConsent', '0');

  const dismissCookieBanner = () => setCookieConsent('1');

  if (cookieConsent === '0') {
    return (
      <section className="mx-auto w-full max-w-screen-xl bg-rt-dark-grey text-sm px-5 py-5 flex flex-col lg:items-center lg:flex-row">
        <div className="flex items-center flex-1 mb-4 lg:mr-8 lg:mb-0">
          <span className="hidden lg:inline-flex items-center justify-center text-white opacity-40 rounded-full mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <p className="text-white leading-5">
            Our website uses cookies to monitor traffic and ensure that we can provide you with the
            best online experience possible. Please read our{' '}
            <Link href="/privacy-policy">
              <a className="underline hover:no-underline focus:no-underline">privacy policy</a>
            </Link>{' '}
            to view more details on the cookies we use.
          </p>
        </div>
        <div className="flex flex-initial items-start">
          <button
            className="inline-flex items-center px-6 py-3 border border-rt-orange text-white rounded-full"
            onClick={dismissCookieBanner}>
            Accept
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </section>
    );
  }

  return <></>;
};

export default CookieBanner;
